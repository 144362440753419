import React from "react";
import "./loading.css";

const Loading1 = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span class="loader"></span>
    </div>
  );
};

export default Loading1;
