import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteTimelog = createAsyncThunk(
  "admin/delete-timelog",
  async ({ timelogID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timelogDetailsURL.replace("{id}", timelogID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const timelogDeleteSlice = createSlice({
  name: "delete-timelog",
  initialState: {
    timelogDeleteFetching: false,
    timelogDeleteSuccess: false,
    timelogDeleteError: false,
    timelogDeleteErrorMessage: "",
  },
  reducers: {
    timelogDeleteClearState: (state) => {
      state.timelogDeleteError = false;
      state.timelogDeleteSuccess = false;
      state.timelogDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteTimelog.fulfilled]: (state, { payload }) => {
      state.timelogDeleteFetching = false;
      state.timelogDeleteSuccess = true;
      return state;
    },
    [deleteTimelog.rejected]: (state, action) => {
      state.timelogDeleteFetching = false;
      state.timelogDeleteError = true;
      state.timelogDeleteErrorMessage = action?.payload;
    },
    [deleteTimelog.pending]: (state) => {
      state.timelogDeleteFetching = true;
    },
  },
});

export const { timelogDeleteClearState } = timelogDeleteSlice.actions;

export default timelogDeleteSlice.reducer;
