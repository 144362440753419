import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchBalanceKeeperDetails = createAsyncThunk(
  "admin/settings/balanceKeeper-details",
  async (rejectWithValue) => {
    try {
      const response = await api.actionHandler({
        url: api.balanceKeeperDetailsListURL,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();


      return rejectWithValue(message);
    }
  }
);
const balanceKeeperDetailsSlice = createSlice({
  name: "balanceKeeper-details",
  initialState: {
    balanceKeeperList: {},
    bdlFetching: false,
    bdlSuccess: false,
    bdlError: false,
    bdlErrorMessage: "",
  },
  reducers: {
    clearBdlState: (state) => {
      state.bdlError = false;
      state.bdlSuccess = false;
      state.bdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBalanceKeeperDetails.fulfilled]: (state, { payload }) => {
      state.balanceKeeperList = payload.data;
      state.bdlFetching = false;
      state.bdlSuccess = true;
      return state;
    },
    [fetchBalanceKeeperDetails.rejected]: (state, action) => {
      state.bdlFetching = false;
      state.bdlError = true;
      state.bdlErrorMessage = action?.payload;
    },
    [fetchBalanceKeeperDetails.pending]: (state) => {
      state.bdlFetching = true;
    },
  },
});

export const { clearBdlState } = balanceKeeperDetailsSlice.actions;

export default balanceKeeperDetailsSlice.reducer;
