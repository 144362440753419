import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteTransfer = createAsyncThunk(
  "admin/transfer-delete",
  async ({ transferID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transferDetailsURL.replace("{id}", transferID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transferDeleteSlice = createSlice({
  name: "transfer-delete",
  initialState: {
    deleteTransferFetching: false,
    deleteTransferSuccess: false,
    deleteTransferError: false,
    deleteTransferErrorMessage: "",
  },
  reducers: {
    clearDeleteTransfer: (state) => {
      state.deleteTransferError = false;
      state.deleteTransferSuccess = false;
      state.deleteTransferFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteTransfer.fulfilled]: (state, { payload }) => {
      state.deleteTransferFetching = false;
      state.deleteTransferSuccess = true;
      return state;
    },
    [deleteTransfer.rejected]: (state, action) => {
      state.deleteTransferFetching = false;
      state.deleteTransferError = true;
      state.deleteTransferErrorMessage = action?.payload;
    },
    [deleteTransfer.pending]: (state) => {
      state.deleteTransferFetching = true;
    },
  },
});

export const { clearDeleteTransfer } =
  transferDeleteSlice.actions;

export default transferDeleteSlice.reducer;
