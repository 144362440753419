import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editSubCategories = createAsyncThunk(
  "admin/sub-categories-edit",
  async ({ payload, typeID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionSubCategoryDetailsURL.replace("{id}", typeID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const transSubCategoriesEditSlice = createSlice({
  name: "sub-categories-edit",
  initialState: {
    tsceFetching: false,
    tsceSuccess: false,
    tsceError: false,
    tsceerrorMessage: "",
  },
  reducers: {
    tsceClearState: (state) => {
      state.tsceError = false;
      state.tsceSuccess = false;
      state.tsceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editSubCategories.fulfilled]: (state, { payload }) => {
      state.tsceFetching = false;
      state.tsceSuccess = true;
      return state;
    },
    [editSubCategories.rejected]: (state, action) => {
      state.tsceFetching = false;
      state.tsceError = true;
      state.tsceerrorMessage = action?.payload;
    },
    [editSubCategories.pending]: (state) => {
      state.tsceFetching = true;
    },
  },
});

export const { tsceClearState } = transSubCategoriesEditSlice.actions;

export default transSubCategoriesEditSlice.reducer;
