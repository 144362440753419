import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteAdjustBankBalance = createAsyncThunk(
  "admin/adjustBankBalance-delete",
  async ({ adjustBankBalanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustBankBalanceDetailsURL.replace("{id}", adjustBankBalanceID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adjustBankBalanceDeleteSlice = createSlice({
  name: "adjustBankBalance-delete",
  initialState: {
    deleteAdjustBankBalanceFetching: false,
    deleteAdjustBankBalanceSuccess: false,
    deleteAdjustBankBalanceError: false,
    deleteAdjustBankBalanceErrorMessage: "",
  },
  reducers: {
    clearDeleteAdjustBankBalanceState: (state) => {
      state.deleteAdjustBankBalanceError = false;
      state.deleteAdjustBankBalanceSuccess = false;
      state.deleteAdjustBankBalanceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteAdjustBankBalance.fulfilled]: (state, { payload }) => {
      state.deleteAdjustBankBalanceFetching = false;
      state.deleteAdjustBankBalanceSuccess = true;
      return state;
    },
    [deleteAdjustBankBalance.rejected]: (state, action) => {
      state.deleteAdjustBankBalanceFetching = false;
      state.deleteAdjustBankBalanceError = true;
      state.deleteAdjustBankBalanceErrorMessage = action?.payload;
    },
    [deleteAdjustBankBalance.pending]: (state) => {
      state.deleteAdjustBankBalanceFetching = true;
    },
  },
});

export const { clearDeleteAdjustBankBalanceState } =
  adjustBankBalanceDeleteSlice.actions;

export default adjustBankBalanceDeleteSlice.reducer;
