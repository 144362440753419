import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchProjectDetails = createAsyncThunk(
  "admin/project-details",
  async ({ projectID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectDetailsURL.replace("{id}", projectID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectDetailsSlice = createSlice({
  name: "project-details",
  initialState: {
    projectDetails: {},
    projectDetailsFetching: false,
    projectDetailsSuccess: false,
    projectDetailsError: false,
    projectDetailsErrorMessage: "",
  },
  reducers: {
    clearFddState: (state) => {
      state.projectDetailsError = false;
      state.projectDetailsSuccess = false;
      state.projectDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchProjectDetails.fulfilled]: (state, { payload }) => {
      state.projectDetails = payload.data;

      state.projectDetailsFetching = false;
      state.projectDetailsSuccess = true;
      return state;
    },
    [fetchProjectDetails.rejected]: (state, action) => {
      state.projectDetailsFetching = false;
      state.projectDetailsError = true;
      state.projectDetailsErrorMessage = action?.payload;
    },
    [fetchProjectDetails.pending]: (state) => {
      state.projectDetailsFetching = true;
    },
  },
});
export const { clearFddState } = projectDetailsSlice.actions;

export default projectDetailsSlice.reducer;
