import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchSubCategoryDetails = createAsyncThunk(
  "admin/sub-categories-details",
  async ({ typeID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionSubCategoryDetailsURL.replace("{id}", typeID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const transSubCategoriesDetailsSLice = createSlice({
  name: "sub-categories-details",
  initialState: {
    subCategoryDetails: {},
    tscdFetching: false,
    tscdSuccess: false,
    tscdError: false,
    tscderrorMessage: "",
  },
  reducers: {
    tscdClearState: (state) => {
      state.tscdError = false;
      state.tscdSuccess = false;
      state.tscdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubCategoryDetails.fulfilled]: (state, { payload }) => {
      state.subCategoryDetails = payload.data;
      state.tscdFetching = false;
      state.tscdSuccess = true;
      return state;
    },
    [fetchSubCategoryDetails.rejected]: (state, { payload }) => {
      state.tscdFetching = false;
      state.tscdError = true;
      state.tscderrorMessage = payload?.message;
    },
    [fetchSubCategoryDetails.pending]: (state) => {
      state.tscdFetching = true;
    },
  },
});

export const { tscdClearState } = transSubCategoriesDetailsSLice.actions;

export default transSubCategoriesDetailsSLice.reducer;
