import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const projectEdit = createAsyncThunk(
  "admin/project-edit",
  async ({payload, projectID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectDetailsURL.replace("{id}", projectID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectEditSlice = createSlice({
  name: "project-edit",
  initialState: {
    projectEditFetching: false,
    projectEditSuccess: false,
    projectEditError: false,
    projectEditErrorMessage: "",
  },
  reducers: {
    clearProjectEditState: (state) => {
      state.projectEditError = false;
      state.projectEditSuccess = false;
      state.projectEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [projectEdit.fulfilled]: (state, { payload }) => {
      state.projectEditFetching = false;
      state.projectEditSuccess = true;
      return state;
    },
    [projectEdit.rejected]: (state, action) => {
      state.projectEditFetching = false;
      state.projectEditError = true;
      state.projectEditErrorMessage = action?.payload;
    },
    [projectEdit.pending]: (state) => {
      state.projectEditFetching = true;
    },
  },
});
export const { clearProjectEditState } = projectEditSlice.actions;

export default projectEditSlice.reducer;
