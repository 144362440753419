import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTransactionList = createAsyncThunk(
  "admin/cashbook/fetch-Transaction-list",
  async (
    { type, fromDate, toDate, limit, offset, category },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.actionHandler({
        url:
          fromDate === "" || toDate === ""
            ? api.transactionListURL
                .replace("{type}", type)
                .replace("&from_date={from_date}&", "")
                .replace("to_date={to_date}", "")
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{category}", category)
            : api.transactionListURL
                .replace("{type}", type)
                .replace("{from_date}", fromDate)
                .replace("{to_date}", toDate)
                .replace("{limit}", limit)
                .replace("{offset}", offset)
                .replace("{category}", category),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      console.log("catched error: ", e);
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      console.log("catched messaged: ", message);
      return rejectWithValue(message);
    }
  }
);
const transactionListSlice = createSlice({
  name: "fetch-Transaction-list",
  initialState: {
    transactionList: [],
    ftlFetching: false,
    ftlSuccess: false,
    ftlError: false,
    ftlErrorMessage: "",
    transactionCount: null,
  },
  reducers: {
    ftlClearState: (state) => {
      state.ftlError = false;
      state.ftlSuccess = false;
      state.ftlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTransactionList.fulfilled]: (state, { payload }) => {
      state.transactionList = [];
      payload.data.results.forEach((item) => state.transactionList.push(item));
      state.transactionCount = payload.data.count;
      state.ftlFetching = false;
      state.ftlSuccess = true;
      return state;
    },
    [fetchTransactionList.rejected]: (state, action) => {
      state.ftlFetching = false;
      state.ftlError = true;
      state.ftlErrorMessage = action?.payload;
    },
    [fetchTransactionList.pending]: (state) => {
      state.ftlFetching = true;
    },
  },
});

export const { ftlClearState } = transactionListSlice.actions;

export default transactionListSlice.reducer;
