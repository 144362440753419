import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const createProjectStatus = createAsyncThunk(
  "admin/create-projectStatus",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectStatusPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectStatusCreateSlice = createSlice({
  name: "create-projectStatus",
  initialState: {
    projectStatusCreateFetching: false,
    projectStatusCreateSuccess: false,
    projectStatusCreateError: false,
    projectStatusCreateErrorMessage: "",
  },
  reducers: {
    clearProjectStatusCreateState: (state) => {
      state.projectStatusCreateError = false;
      state.projectStatusCreateSuccess = false;
      state.projectStatusCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createProjectStatus.fulfilled]: (state, { payload }) => {
      state.projectStatusCreateFetching = false;
      state.projectStatusCreateSuccess = true;
      return state;
    },
    [createProjectStatus.rejected]: (state, action) => {
      state.projectStatusCreateFetching = false;
      state.projectStatusCreateError = true;
      state.projectStatusCreateErrorMessage = action?.payload;
    },
    [createProjectStatus.pending]: (state) => {
      state.projectStatusCreateFetching = true;
    },
  },
});

export const { clearProjectStatusCreateState } = projectStatusCreateSlice.actions;

export default projectStatusCreateSlice.reducer;
