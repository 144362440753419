import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchInvestorList = createAsyncThunk(
  "admin/investor-list",
  async ({ limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.investorsListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const investorsListSlice = createSlice({
  name: "investor-list",
  initialState: {
    investorsList: [],
    aslFetching: false,
    aslSuccess: false,
    aslError: false,
    aslErrorMessage: "",
    investorCount: null,
  },
  reducers: {
    aslClearState: (state) => {
      state.aslError = false;
      state.aslSuccess = false;
      state.aslFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchInvestorList.fulfilled]: (state, { payload }) => {
      state.investorsList = [];
      payload.data.results.forEach((item) => state.investorsList.push(item));
      state.investorCount = payload.data.count;
      state.aslFetching = false;
      state.aslSuccess = true;
      return state;
    },
    [fetchInvestorList.rejected]: (state, action) => {
      state.aslFetching = false;
      state.aslError = true;
      state.aslErrorMessage = action?.payload;
    },
    [fetchInvestorList.pending]: (state) => {
      state.aslFetching = true;
    },
  },
});

export const { aslClearState } = investorsListSlice.actions;

export default investorsListSlice.reducer;
