import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const fetchProjectStatusList = createAsyncThunk(
  "admin/projectStatus-list",
  async ({ limit, offset  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectStatusListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const projectStatusListSlice = createSlice({
  name: "projectStatus-list",
  initialState: {
    projectStatusList: [],
    fdlFetching: false,
    fdlSuccess: false,
    fdlError: false,
    fdlErrorMessage: "",
    projectStatusCount: null,
  },
  reducers: {
    fdlClearState: (state) => {
      state.fdlError = false;
      state.fdlSuccess = false;
      state.fdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchProjectStatusList.fulfilled]: (state, { payload }) => {
      state.projectStatusList = [];
      payload.data.results.forEach((item) => state.projectStatusList.push(item));
      state.projectStatusCount = payload.data.count;
      state.fdlFetching = false;
      state.fdlSuccess = true;
      return state;
    },
    [fetchProjectStatusList.rejected]: (state, action) => {
      state.fdlFetching = false;
      state.fdlError = true;
      state.fdlErrorMessage = action?.payload;
    },
    [fetchProjectStatusList.pending]: (state) => {
      state.fdlFetching = true;
    },
  },
});

export const { fdlClearState } = projectStatusListSlice.actions;

export default projectStatusListSlice.reducer;
