import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editTransaction = createAsyncThunk(
  "admin/Transaction-edit",
  async ({ payload, transactionID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionDetailsURL.replace("{id}", transactionID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transactionEditSlice = createSlice({
  name: "Transaction-edit",
  initialState: {
    tedFetching: false,
    tedSuccess: false,
    tedError: false,
    tedErrorMessage: "",
  },
  reducers: {
    clearTedState: (state) => {
      state.tedError = false;
      state.tedSuccess = false;
      state.tedFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editTransaction.fulfilled]: (state, { payload }) => {
      state.tedFetching = false;
      state.tedSuccess = true;
      return state;
    },
    [editTransaction.rejected]: (state, action) => {
      state.tedFetching = false;
      state.tedError = true;
      state.tedErrorMessage = action?.payload;
    },
    [editTransaction.pending]: (state) => {
      state.tedFetching = true;
    },
  },
});

export const { clearTedState } = transactionEditSlice.actions;

export default transactionEditSlice.reducer;
