import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchDashboardTransactionList = createAsyncThunk(
  "admin/cashbook/dashboard-Transaction-list",
  async ({ type, fromDate, toDate }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          (fromDate === "" || toDate === "")
            ?api.transactionListURL.replace("{type}", type).replace("&from_date={from_date}&","").replace("to_date={to_date}",""):api.transactionListURL
                .replace("{type}", type).replace("{from_date}", fromDate).replace("{to_date}", toDate),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      console.log("catched error: ", e);
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      console.log("catched messaged: ", message);
      return rejectWithValue(message);
    }
  }
);
const dashboardIncomeSlice = createSlice({
  name: "dashboard-Transaction-list",
  initialState: {
    transaction: [],
    ftdlFetching: false,
    ftdlSuccess: false,
    ftdlError: false,
    ftdlErrorMessage: "",
  },
  reducers: {
    ftdlClearState: (state) => {
      state.ftdlError = false;
      state.ftdlSuccess = false;
      state.ftdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDashboardTransactionList.fulfilled]: (state, { payload }) => {
      state.transaction = [];
      payload.data.results.forEach((item) => state.transaction.push(item));

      state.ftdlFetching = false;
      state.ftdlSuccess = true;
      return state;
    },
    [fetchDashboardTransactionList.rejected]: (state, action) => {
      state.ftdlFetching = false;
      state.ftdlError = true;
      state.ftdlErrorMessage = action?.payload;
    },
    [fetchDashboardTransactionList.pending]: (state) => {
      state.ftdlFetching = true;
    },
  },
});

export const { ftdlClearState } = dashboardIncomeSlice.actions;

export default dashboardIncomeSlice.reducer;
