import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteBankAccount = createAsyncThunk(
  "admin/settings/delete-bank-account",
  async ({ bankID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.bankAccountDetailsURL.replace("{id}", bankID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const deleteBankAccountSlice = createSlice({
  name: "delete-bank-account",
  initialState: {
    dbaFetching: false,
    dbaSuccess: false,
    dbaError: false,
    dbaErrorMessage: "",
  },
  reducers: {
    dbaClearState: (state) => {
      state.dbaError = false;
      state.dbaSuccess = false;
      state.dbaFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteBankAccount.fulfilled]: (state, { payload }) => {
      state.dbaFetching = false;
      state.dbaSuccess = true;
      return state;
    },
    [deleteBankAccount.rejected]: (state, action) => {
      state.dbaFetching = false;
      state.dbaError = true;
      state.dbaErrorMessage = action?.payload;
    },
    [deleteBankAccount.pending]: (state) => {
      state.dbaFetching = true;
    },
  },
});

export const { dbaClearState } = deleteBankAccountSlice.actions;

export default deleteBankAccountSlice.reducer;
