import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchAdjustCashBalanceDetails = createAsyncThunk(
  "admin/adjustCashBalance-details",
  async ({ adjustCashBalanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustCashBalanceDetailsURL.replace("{id}", adjustCashBalanceID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adjustCashBalanceDetailsSlice = createSlice({
  name: "adjustCashBalance-details",
  initialState: {
    adjustCashBalanceDetails: {},
    etdFetching: false,
    etdSuccess: false,
    etdError: false,
    etdErrorMessage: "",
  },
  reducers: {
    clearEtdState: (state) => {
      state.etdError = false;
      state.etdSuccess = false;
      state.etdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAdjustCashBalanceDetails.fulfilled]: (state, { payload }) => {
      state.adjustCashBalanceDetails = payload.data;

      state.etdFetching = false;
      state.etdSuccess = true;
      return state;
    },
    [fetchAdjustCashBalanceDetails.rejected]: (state, action) => {
      state.etdFetching = false;
      state.etdError = true;
      state.etdErrorMessage = action?.payload;
    },
    [fetchAdjustCashBalanceDetails.pending]: (state) => {
      state.etdFetching = true;
    },
  },
});
export const { clearEtdState } = adjustCashBalanceDetailsSlice.actions;

export default adjustCashBalanceDetailsSlice.reducer;
