import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchSubcategoriesList = createAsyncThunk(
  "admin/settings/trans-subcategories-list",
  async ({typeID ,type}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionSubCategoriesURL.replace("{id}",typeID).replace("{type}",type),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      console.log("catched error: ", e);
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      console.log("catched messaged: ", message);
      return rejectWithValue(message);
    }
  }
);
const transSubCategoriesListSlice = createSlice({
  name: "trans-subcategories-list",
  initialState: {
    subCategoryList: [],
    tsclFetching: false,
    tsclSuccess: false,
    tsclError: false,
    tsclErrorMessage: "",
  },
  reducers: {
    tsclClearState: (state) => {
      state.tsclError = false;
      state.tsclSuccess = false;
      state.tsclFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchSubcategoriesList.fulfilled]: (state, { payload }) => {
      state.subCategoryList = [];
      payload.data.results.forEach((item) => state.subCategoryList.push(item));

      state.tsclFetching = false;
      state.tsclSuccess = true;
      return state;
    },
    [fetchSubcategoriesList.rejected]: (state, action) => {
      state.tsclFetching = false;
      state.tsclError = true;
      state.tsclErrorMessage = action?.payload;
    },
    [fetchSubcategoriesList.pending]: (state) => {
      state.tsclFetching = true;
    },
  },
});

export const { tsclClearState } = transSubCategoriesListSlice.actions;

export default transSubCategoriesListSlice.reducer;
