import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteTransaction = createAsyncThunk(
  "admin/transaction-delete",
  async ({ transactionID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionDetailsURL.replace(
          "{id}",
          transactionID
        ),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transactionDeleteSlice = createSlice({
  name: "transaction-delete",
  initialState: {
    deleteTransactionFetching: false,
    deleteTransactionSuccess: false,
    deleteTransactionError: false,
    deleteTransactionErrorMessage: "",
  },
  reducers: {
    clearDeleteTransactionState: (state) => {
      state.deleteTransactionSuccess = false;
      state.deleteTransactionError = false;
      state.deleteTransactionFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteTransaction.fulfilled]: (state, { payload }) => {
      state.deleteTransactionFetching = false;
      state.deleteTransactionSuccess = true;
      return state;
    },
    [deleteTransaction.rejected]: (state, action) => {
      state.deleteTransactionFetching = false;
      state.deleteTransactionError = true;
      state.deleteTransactionErrorMessage = action?.payload;
    },
    [deleteTransaction.pending]: (state) => {
      state.deleteTransactionFetching = true;
    },
  },
});

export const { clearDeleteTransactionState } =
  transactionDeleteSlice.actions;

export default transactionDeleteSlice.reducer;
