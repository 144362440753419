import axios from "axios";

const actionHandler = (payload) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Cache-Control"] = "no-cache";

  const token = localStorage.getItem("admin-token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return new Promise((resolve, reject) => {
    //Production
    payload.baseURL = "https://dev.enfono.com/api_enfin/api/v1/";

    axios(payload)
      .then((response) => {
        let resp = response.data;
        if (response.status >= 200 && response.status < 300) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};



axios.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status;
  if (statusCode == undefined) {
    // Server needs to specify CORS headers in the response
    // Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
    // Otherwise, these kinda issues happen

    var lineSplit = err.toString().split("\n")[0].split(" ");
    statusCode = lineSplit[lineSplit.length - 1];
  }
  return new Promise(() => {
    if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
      // Got an unauthorized, logout the staff
      localStorage.removeItem("admin-token");
      window.location.pathname = "/auth/sign-in";
    }
    throw err;
  });
});

export default {
  /* auth URLs */
  loginURL: "auth/login/", // [POST]
  logoutURL: "auth/logout/", // [POST]
  profileURL: "auth/profile/", // [GET, PUT]

  dashboardDataURL: "utilities/dashboard/",
  dashboardReportDetailsURL: "utilities/dashboard/financial_report/",

  //Investors
  investorsListURL: "utilities/investors/?limit={limit}&offset={offset}",
  investorAddURL: "utilities/investors/",
  investorDetailsURL: "utilities/investor/{id}/",

  //Clients
  clientsListURL: "auth/client/?limit={limit}&offset={offset}",
  clientPostURL: "auth/client/",
  clientDetailsURL: "auth/client/{id}/",

  //HR
  userListURL: "auth/staff/?search={search}&limit={limit}&offset={offset}",
  userPostURL: "auth/staff/",
  userDetailsURL: "auth/staff/{id}/",

  //Adjust Balance
  adjustBankBalanceListURL: "utilities/adjust-bank-balances/?limit={limit}&offset={offset}",
  adjustBankBalancePostURL:"utilities/adjust-bank-balances/",
  adjustBankBalanceDetailsURL: "utilities/adjust-bank-balance/{id}/",

  adjustCashBalanceListURL: "utilities/adjust-cash-balances/?limit={limit}&offset={offset}",
  adjustCashBalancePostURL:"utilities/adjust-cash-balances/",
  adjustCashBalanceDetailsURL: "utilities/adjust-cash-balance/{id}/",

  //Transaction
  transactionListURL:"utilities/transactions/?type={type}&from_date={from_date}&to_date={to_date}&limit={limit}&offset={offset}&category={category}",
  transactionCreateURL: "utilities/transactions/",
  transactionDetailsURL: "utilities/transaction/{id}/",

  //Transfers
  transferListURL: "utilities/transfer/?limit={limit}&offset={offset}",
  transferPostURL: "utilities/transfer/",
  transferDetailsURL: "utilities/transfer/{id}/",

  bankAccountListURL: "utilities/bank_accounts/",
  bankAccountDetailsURL: "utilities/bank_account/{id}/",
  bankTransactionsListURL:"utilities/bank/{bank_id}/transactions/?limit={limit}&offset={offset}",

  balanceKeeperDetailsListURL: "utilities/balance_keeper/",

  transactionCategoriesURL: "utilities/transaction_categories/?limit={limit}&offset={offset}&type={type}",
  transactionCategoriesPostURL: "utilities/transaction_categories/",
  transactionCategoryDetailsURL: "utilities/transaction_category/{id}/",

  transactionSubCategoriesURL:"utilities/transaction_category/{id}/sub_categories/?type={type}",
  transactionSubCategoriesPostURL:"utilities/transaction_category/{id}/sub_categories/",
  transactionSubCategoryDetailsURL: "utilities/transaction_sub_category/{id}/",

  //Reports
  reportsListURL: "utilities/financial_report/?from_date={from_date}&to_date={to_date}",

  //Projects
  projectsListURL: "projects/projects/?limit={limit}&offset={offset}",
  projectPostURL: "projects/projects/",
  projectDetailsURL: "projects/projects/{id}/",
  assigneeListURL:"projects/projects/{id}/assignees/?limit={limit}&offset={offset}",

   //Tasks
  tasksListURL: "projects/tasks/?limit={limit}&offset={offset}&project_id={project_id}",
  taskPostURL: "projects/tasks/",
  taskDetailsURL: "projects/tasks/{id}/",
  startTimerURL:"projects/tasks/{id}/timer_action/",
  timerDetailsURL:"projects/tasks/{id}/timer_status/",

  //Timezone
  timezonesListURL:"common/timezones/?limit={limit}&offset={offset}",
  timezonePostURL:"common/timezones",
  timezoneDetailsURL:"common/timezone/{id}",

  //TimeLog
  timelogListURL:"projects/timelogs/?limit={limit}&offset={offset}&project_id={project_id}",
  timelogPostURL:"projects/timelogs/",
  timelogDetailsURL:"projects/timelogs/{id}/",

  //States
  statesListURL:"common/states/?limit={limit}&offset={offset}&country_id={country_id}",
  statePostURL:"common/states",
  stateDetailsURL:"common/state/{id}",

  //Countries
  countriesListURL:"common/countries/?limit={limit}&offset={offset}",
  countryPostURL:"common/countries",
  countryDetailsURL:"common/country/{id}",

  //Project Status
  projectStatusListURL:"projects/status/?limit={limit}&offset={offset}",
  projectStatusPostURL:"projects/status/",
  projectStatusDetailsURL:"/projects/status/{id}/",

  //Task Status
  taskStatusListURL:"projects/task-status/?limit={limit}&offset={offset}",
  taskStatusPostURL:"projects/task-status/",
  taskStatusDetailsURL:"projects/task-status/{id}/",


  actionHandler,
};
