import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchBankDetails = createAsyncThunk(
  "admin/bank-details",
  async ({ accountID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.bankAccountDetailsURL.replace("{id}", accountID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const bankAccountDetailsSlice = createSlice({
  name: "bank-details",
  initialState: {
    bankDetails: {},
    bdFetching: false,
    bdSuccess: false,
    bdError: false,
    bderrorMessage: "",
  },
  reducers: {
    bdClearState: (state) => {
      state.bdError = false;
      state.bdSuccess = false;
      state.bdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchBankDetails.fulfilled]: (state, { payload }) => {
      state.bankDetails = payload.data;
      state.bdFetching = false;
      state.bdSuccess = true;
      return state;
    },
    [fetchBankDetails.rejected]: (state, { payload }) => {
      state.bdFetching = false;
      state.bdError = true;
      state.bderrorMessage = payload?.message;
    },
    [fetchBankDetails.pending]: (state) => {
      state.bdFetching = true;
    },
  },
});

export const { bdClearState } = bankAccountDetailsSlice.actions;

export default bankAccountDetailsSlice.reducer;
