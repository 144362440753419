import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const deleteClient = createAsyncThunk(
  "admin/delete-client",
  async ({ clientID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.clientDetailsURL.replace("{id}", clientID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const clientDeleteSlice = createSlice({
  name: "delete-client",
  initialState: {
    clientDeleteFetching: false,
    clientDeleteSuccess: false,
    clientDeleteError: false,
    clientDeleteErrorMessage: "",
  },
  reducers: {
    clientDeleteClearState: (state) => {
      state.clientDeleteError = false;
      state.clientDeleteSuccess = false;
      state.clientDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteClient.fulfilled]: (state, { payload }) => {
      state.clientDeleteFetching = false;
      state.clientDeleteSuccess = true;
      return state;
    },
    [deleteClient.rejected]: (state, action) => {
      state.clientDeleteFetching = false;
      state.clientDeleteError = true;
      state.clientDeleteErrorMessage = action?.payload;
    },
    [deleteClient.pending]: (state) => {
      state.clientDeleteFetching = true;
    },
  },
});

export const { clientDeleteClearState } = clientDeleteSlice.actions;

export default clientDeleteSlice.reducer;
