import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchStateDetails = createAsyncThunk(
  "admin/state-details",
  async ({ stateID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.stateDetailsURL.replace("{id}", stateID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const stateDetailsSlice = createSlice({
  name: "state-details",
  initialState: {
    stateDetails: {},
    stateDetailsFetching: false,
    stateDetailsSuccess: false,
    stateDetailsError: false,
    stateDetailsErrorMessage: "",
  },
  reducers: {
    clearFddState: (state) => {
      state.stateDetailsError = false;
      state.stateDetailsSuccess = false;
      state.stateDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchStateDetails.fulfilled]: (state, { payload }) => {
      state.stateDetails = payload.data;

      state.stateDetailsFetching = false;
      state.stateDetailsSuccess = true;
      return state;
    },
    [fetchStateDetails.rejected]: (state, action) => {
      state.stateDetailsFetching = false;
      state.stateDetailsError = true;
      state.stateDetailsErrorMessage = action?.payload;
    },
    [fetchStateDetails.pending]: (state) => {
      state.stateDetailsFetching = true;
    },
  },
});
export const { clearFddState } = stateDetailsSlice.actions;

export default stateDetailsSlice.reducer;
