import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTimelogList = createAsyncThunk(
  "admin/timelogs-list",
  async ({ limit, offset,projectID,taskID,staffID  }, { rejectWithValue }) => {
    try {
      let url =api.timelogListURL.replace("{limit}", limit).replace("{offset}", offset).replace("{project_id}",projectID)
      if(taskID)
        {
          url += `&task_id=${taskID}`
        }
      if(staffID)
        {
          url += `&staff_id=${staffID}`
        }
      const response = await api.actionHandler({
        url: url,
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timelogListSlice = createSlice({
  name: "timelogs-list",
  initialState: {
    timelogsList: [],
    fdlFetching: false,
    fdlSuccess: false,
    fdlError: false,
    fdlErrorMessage: "",
    timelogCount: null,
  },
  reducers: {
    fdlClearState: (state) => {
      state.fdlError = false;
      state.fdlSuccess = false;
      state.fdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTimelogList.fulfilled]: (state, { payload }) => {
      state.timelogsList = [];
      payload.data.results.forEach((item) => state.timelogsList.push(item));
      state.timelogCount = payload.data.count;
      state.fdlFetching = false;
      state.fdlSuccess = true;
      return state;
    },
    [fetchTimelogList.rejected]: (state, action) => {
      state.fdlFetching = false;
      state.fdlError = true;
      state.fdlErrorMessage = action?.payload;
    },
    [fetchTimelogList.pending]: (state) => {
      state.fdlFetching = true;
    },
  },
});

export const { fdlClearState } = timelogListSlice.actions;

export default timelogListSlice.reducer;
