import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchAdjustCashBalanceList = createAsyncThunk(
  "admin/cashbook/adjustCashBalance-list",
  async ({limit ,offset}, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustCashBalanceListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const adjustCashBalanceListSlice = createSlice({
  name: "adjustCashBalance-list",
  initialState: {
    adjustCashBalanceList: [],
    etlFetching: false,
    etlSuccess: false,
    etlError: false,
    etlErrorMessage: "",
    adjustCashBalanceCount: null,
  },
  reducers: {
    etlClearState: (state) => {
      state.etlError = false;
      state.etlSuccess = false;
      state.etlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAdjustCashBalanceList.fulfilled]: (state, { payload }) => {
      state.adjustCashBalanceList = [];
      payload.data.results.forEach((item) =>
        state.adjustCashBalanceList.push(item)
      );
      state.adjustCashBalanceCount = payload.data.count;

      state.etlFetching = false;
      state.etlSuccess = true;
      return state;
    },
    [fetchAdjustCashBalanceList.rejected]: (state, action) => {
      state.etlFetching = false;
      state.etlError = true;
      state.etlErrorMessage = action?.payload;
    },
    [fetchAdjustCashBalanceList.pending]: (state) => {
      state.etlFetching = true;
    },
  },
});

export const { etlClearState } = adjustCashBalanceListSlice.actions;

export default adjustCashBalanceListSlice.reducer;
