import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const createTimezones = createAsyncThunk(
  "admin/create-timezones",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timezonePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timezoneCreateSlice = createSlice({
  name: "create-timezones",
  initialState: {
    timezoneCreateFetching: false,
    timezoneCreateSuccess: false,
    timezoneCreateError: false,
    timezoneCreateErrorMessage: "",
  },
  reducers: {
    clearTimezoneCreateState: (state) => {
      state.timezoneCreateError = false;
      state.timezoneCreateSuccess = false;
      state.timezoneCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createTimezones.fulfilled]: (state, { payload }) => {
      state.timezoneCreateFetching = false;
      state.timezoneCreateSuccess = true;
      return state;
    },
    [createTimezones.rejected]: (state, action) => {
      state.timezoneCreateFetching = false;
      state.timezoneCreateError = true;
      state.timezoneCreateErrorMessage = action?.payload;
    },
    [createTimezones.pending]: (state) => {
      state.timezoneCreateFetching = true;
    },
  },
});

export const { clearTimezoneCreateState } = timezoneCreateSlice.actions;

export default timezoneCreateSlice.reducer;
