import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const createTaskStatus = createAsyncThunk(
  "admin/create-taskStatus",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.taskStatusPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const taskStatusCreateSlice = createSlice({
  name: "create-taskStatus",
  initialState: {
    taskStatusCreateFetching: false,
    taskStatusCreateSuccess: false,
    taskStatusCreateError: false,
    taskStatusCreateErrorMessage: "",
  },
  reducers: {
    clearTaskStatusCreateState: (state) => {
      state.taskStatusCreateError = false;
      state.taskStatusCreateSuccess = false;
      state.taskStatusCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createTaskStatus.fulfilled]: (state, { payload }) => {
      state.taskStatusCreateFetching = false;
      state.taskStatusCreateSuccess = true;
      return state;
    },
    [createTaskStatus.rejected]: (state, action) => {
      state.taskStatusCreateFetching = false;
      state.taskStatusCreateError = true;
      state.taskStatusCreateErrorMessage = action?.payload;
    },
    [createTaskStatus.pending]: (state) => {
      state.taskStatusCreateFetching = true;
    },
  },
});

export const { clearTaskStatusCreateState } = taskStatusCreateSlice.actions;

export default taskStatusCreateSlice.reducer;
