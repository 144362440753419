import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteState = createAsyncThunk(
  "admin/delete-state",
  async ({ stateID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.stateDetailsURL.replace("{id}", stateID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const stateDeleteSlice = createSlice({
  name: "delete-state",
  initialState: {
    stateDeleteFetching: false,
    stateDeleteSuccess: false,
    stateDeleteError: false,
    stateDeleteErrorMessage: "",
  },
  reducers: {
    stateDeleteClearState: (state) => {
      state.stateDeleteError = false;
      state.stateDeleteSuccess = false;
      state.stateDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteState.fulfilled]: (state, { payload }) => {
      state.stateDeleteFetching = false;
      state.stateDeleteSuccess = true;
      return state;
    },
    [deleteState.rejected]: (state, action) => {
      state.stateDeleteFetching = false;
      state.stateDeleteError = true;
      state.stateDeleteErrorMessage = action?.payload;
    },
    [deleteState.pending]: (state) => {
      state.stateDeleteFetching = true;
    },
  },
});

export const { stateDeleteClearState } = stateDeleteSlice.actions;

export default stateDeleteSlice.reducer;
