import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

const Index = lazy(() => import("../views/dashboard/index"));

//Clients
const Clients = lazy(() => import("../views/Clients/Clients"));

const Projects = lazy(()=>import("../views/Projects/Projects"));
const ProjectDashboard = lazy(() => import("../views/Projects/Dashboard/Dashboard"));

const Tasks  = lazy(() => import("../views/Tasks/Tasks"));


//Asset Management
const Investors = lazy(() => import("../views/Investors/index"));

//HR
const HR = lazy(() => import("../views/HR/index"));

//Cashbook
const ExpenseTransaction = lazy(() =>import("../views/cashbook/expense/index"));
const IncomeTransaction = lazy(() => import("../views/cashbook/income/index"));
const AdjustBankBalance = lazy(() => import("../views/cashbook/AdjustBankBalance/Index"));
const AdjustCashBalance =lazy(()=>import("../views/cashbook/AdjustCashBalance/Index"))
const Transfer = lazy(() => import("../views/cashbook/transfers/Transfers"));
const BankTransactions = lazy(() =>import('../views/settings/BankSettings/BankTransactions'));

//Reports
const Reports =lazy(()=>import('../views/reports/IncomevsExpense'))

//Settings
const Settings = lazy(() => import("../views/settings/index"));

//Profile
const UserProfile = lazy(() => import("../views/Profile/ProfileDashboard"));

const States = lazy(() => import("../views/settings/Countries/States/StateList"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Index} />
            <Route path="/clients" exact component={Clients} />
            <Route path="/projects" exact component={Projects} />
            <Route path="/projects/:projectID/:active_tab?" component={ProjectDashboard}/>

            <Route path="/tasks" exact component={Tasks}/>

            <Route exact path="/investors" component={Investors} />

            <Route exact path="/hr-management/:active_tab?" component={HR} />

            <Route path="/settings/:active_tab" component={Settings} />

            {/*Cashbook */}
            <Route path="/cashbook/expenses" component={ExpenseTransaction} />
            <Route path="/cashbook/income" component={IncomeTransaction} />
            <Route path="/cashbook/adjust-bank-balance" component={AdjustBankBalance} />
            <Route path="/cashbook/adjust-cash-balance" component={AdjustCashBalance} />
            <Route path="/cashbook/transfers" component={Transfer} />
            <Route path="/bank/:bankID/transactions" component={BankTransactions} />

            <Route path="/report/finance" component={Reports} />
            <Route path="/country/:countryID/states" component={States} />

            <Route path="/staff-profile" component={UserProfile} />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
