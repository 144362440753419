import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const deleteTimezone = createAsyncThunk(
  "admin/delete-timezone",
  async ({ timezoneID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timezoneDetailsURL.replace("{id}", timezoneID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const timezoneDeleteSlice = createSlice({
  name: "delete-timezone",
  initialState: {
    timezoneDeleteFetching: false,
    timezoneDeleteSuccess: false,
    timezoneDeleteError: false,
    timezoneDeleteErrorMessage: "",
  },
  reducers: {
    timezoneDeleteClearState: (state) => {
      state.timezoneDeleteError = false;
      state.timezoneDeleteSuccess = false;
      state.timezoneDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteTimezone.fulfilled]: (state, { payload }) => {
      state.timezoneDeleteFetching = false;
      state.timezoneDeleteSuccess = true;
      return state;
    },
    [deleteTimezone.rejected]: (state, action) => {
      state.timezoneDeleteFetching = false;
      state.timezoneDeleteError = true;
      state.timezoneDeleteErrorMessage = action?.payload;
    },
    [deleteTimezone.pending]: (state) => {
      state.timezoneDeleteFetching = true;
    },
  },
});

export const { timezoneDeleteClearState } = timezoneDeleteSlice.actions;

export default timezoneDeleteSlice.reducer;
