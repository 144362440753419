import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchAdjustBankBalanceDetails = createAsyncThunk(
  "admin/adjustBankBalance-details",
  async ({ adjustBankBalanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustBankBalanceDetailsURL.replace("{id}", adjustBankBalanceID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adjustBankBalanceDetailsSlice = createSlice({
  name: "adjustBankBalance-details",
  initialState: {
    adjustBankBalanceDetails: {},
    etdFetching: false,
    etdSuccess: false,
    etdError: false,
    etdErrorMessage: "",
  },
  reducers: {
    clearEtdState: (state) => {
      state.etdError = false;
      state.etdSuccess = false;
      state.etdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchAdjustBankBalanceDetails.fulfilled]: (state, { payload }) => {
      state.adjustBankBalanceDetails = payload.data;

      state.etdFetching = false;
      state.etdSuccess = true;
      return state;
    },
    [fetchAdjustBankBalanceDetails.rejected]: (state, action) => {
      state.etdFetching = false;
      state.etdError = true;
      state.etdErrorMessage = action?.payload;
    },
    [fetchAdjustBankBalanceDetails.pending]: (state) => {
      state.etdFetching = true;
    },
  },
});
export const { clearEtdState } = adjustBankBalanceDetailsSlice.actions;

export default adjustBankBalanceDetailsSlice.reducer;
