import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const countryEdit = createAsyncThunk(
  "admin/country-edit",
  async ({payload, countryID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.countryDetailsURL.replace("{id}", countryID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const countryEditSlice = createSlice({
  name: "country-edit",
  initialState: {
    countryEditFetching: false,
    countryEditSuccess: false,
    countryEditError: false,
    countryEditErrorMessage: "",
  },
  reducers: {
    clearCountryEditState: (state) => {
      state.countryEditError = false;
      state.countryEditSuccess = false;
      state.countryEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [countryEdit.fulfilled]: (state, { payload }) => {
      state.countryEditFetching = false;
      state.countryEditSuccess = true;
      return state;
    },
    [countryEdit.rejected]: (state, action) => {
      state.countryEditFetching = false;
      state.countryEditError = true;
      state.countryEditErrorMessage = action?.payload;
    },
    [countryEdit.pending]: (state) => {
      state.countryEditFetching = true;
    },
  },
});
export const { clearCountryEditState } = countryEditSlice.actions;

export default countryEditSlice.reducer;
