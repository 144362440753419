import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const editInvestor = createAsyncThunk(
  "admin/investor-edit",
  async ({ payload, investorID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.investorDetailsURL.replace("{id}", investorID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const investorEditSlice = createSlice({
  name: "investor-edit",
  initialState: {
    investorEditFetching: false,
    investorEditSuccess: false,
    investorEditError: false,
    investorEditErrorMessage: "",
  },
  reducers: {
    clearInvestorEditState: (state) => {
      state.investorEditError = false;
      state.investorEditSuccess = false;
      state.investorEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editInvestor.fulfilled]: (state, { payload }) => {
      state.investorEditFetching = false;
      state.investorEditSuccess = true;
      return state;
    },
    [editInvestor.rejected]: (state, action) => {
      state.investorEditFetching = false;
      state.investorEditError = true;
      state.investorEditErrorMessage = action?.payload;
    },
    [editInvestor.pending]: (state) => {
      state.investorEditFetching = true;
    },
  },
});

export const { clearInvestorEditState } = investorEditSlice.actions;

export default investorEditSlice.reducer;
