import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";
import financialYearListSlice from "./misc/financialYearSlice";

//Login
import userSlice from "./login/loginSlice";
import userProfileSlice from "./profile/userProfileSlice";


import dashboardDataSlice from "./dashboard/dashboardDataSlice";
import dashboardReportSlice from "./dashboard/dashboardReportSlice";
import dashboardIncomeSlice from "./dashboard/dashboardIncomeSlice";

//Investors
import investorsListSlice from "./investors/investorsListSlice";
import investorEditSlice from "./investors/investorEditSlice";
import investorAddSlice from "./investors/investorAddSlice";
import investorDetailsSlice from "./investors/investorDetailSlice";
import investorDeleteSlice from "./investors/investorDeleteSlice";

//Clients
import clientsListSlice from "./clients/clientsListSlice";
import createClientsSlice from "./clients/createClientsSlice";
import clientDetailsSlice from "./clients/clientDetailsSlice";
import clientEditSlice from "./clients/clientEditSlice";
import clientDeleteSlice from "./clients/clientDeleteSlice";

//Projects
import projectListSlice from "./projects/projectListSlice";
import projectDetailsSlice from "./projects/projectDetailsSlice";
import createProjectSlice from "./projects/createProjectSlice";
import projectEditSlice from "./projects/projectEditSlice";
import projectDeleteSlice from "./projects/projectDeleteSlice";
import assigneeListSlice from "./projects/assigneeListSlice";

//Tasks
import taskListSlice from "./projects/tasks/taskListSlice";
import taskCreateSlice from "./projects/tasks/taskCreateSlice";
import taskEditSlice from "./projects/tasks/taskEditSlice";
import taskDetailsSlice from "./projects/tasks/taskDetailsSlice";
import taskDeleteSlice from "./projects/tasks/taskDeleteSlice";
import timerDetailSlice from "./projects/tasks/timerDetailSlice";
import startTimerSlice from "./projects/tasks/startTimerSlice";
//Timelogs
import timelogCreateSlice from "./projects/timelogs/timelogCreateSlice";
import timelogListSlice from "./projects/timelogs/timelogListSlice";
import timelogDetailsSlice from "./projects/timelogs/timelogDetailsSlice";
import timelogEditSlice from "./projects/timelogs/timelogEditSlice";
import timelogDeleteSlice from "./projects/timelogs/timelogDeleteSlice";

//HR
import staffDeleteSlice from "./usermanagement/staff/staffDeleteSlice";
import staffListSlice from "./usermanagement/staff/staffListSlice";
import staffDetailsSlice from "./usermanagement/staff/staffDetailsSlice";
import staffEditSlice from "./usermanagement/staff/staffEditSlice";
import staffAddSlice from "./usermanagement/staff/staffAddSlice";

//Bank
import bankAccountListSlice from "./demoSettings/banks/bankAccountListSlice";
import addBankAccountSlice from "./demoSettings/banks/bankAccountAddSlice";
import bankAccountEditSlice from "./demoSettings/banks/bankAccountEditSlice";
import bankAccountDetailsSlice from "./demoSettings/banks/bankAccountDetailsSlice";
import deleteBankAccountSlice from "./demoSettings/banks/bankAccountDeleteSlice";

import bankTransactionsListSlice from "./demoSettings/banks/bankTransactionsListSlice";


//Business Details
import balanceKeeperDetailsSlice from "./demoSettings/balanceKeeper/balanceKeeperDetailsSlice";
import balanceKeeperDetailsEditSlice from "./demoSettings/balanceKeeper/balanceKeeperDetailsEditSlice";

//Payroll
import salaryListSlice from "./usermanagement/payroll/employeeSalary/salaryListSlice";
import salaryAddSlice from "./usermanagement/payroll/employeeSalary/salaryAddSlice";
import salaryDetailsSlice from "./usermanagement/payroll/employeeSalary/salaryDetailsSlice";
import salaryEditSlice from "./usermanagement/payroll/employeeSalary/salaryEditSlice";

//Categories & Sub Categories
import transCategoriesListSlice from "./demoSettings/transactionCategories/transCategoriesListSlice";
import transCategoriesAddSlice from "./demoSettings/transactionCategories/transCategoriesAddSlice";
import transCategoriesDetailsSlice from "./demoSettings/transactionCategories/transCategoriesDetailsSlice";
import transCategoriesEditSlice from "./demoSettings/transactionCategories/transCategoriesEditSlice";
import transCategoriesDeleteSlice from "./demoSettings/transactionCategories/transCategoriesDeleteSlice";

import transSubCategoriesListSlice from "./demoSettings/transactionSubCategories/transSubCategoriesListSlice";
import transSubCategoriesAddSlice from "./demoSettings/transactionSubCategories/transSubCategoriesAddSlice";
import transSubCategoriesDetailsSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDetailsSlice";
import transSubCategoriesEditSlice from "./demoSettings/transactionSubCategories/transSubCategoriesEditSlice";
import transSubCategoriesDeleteSlice from "./demoSettings/transactionSubCategories/transSubCategoriesDeleteSlice";

//Accounts
import adjustBankBalanceListSlice from "./accounting/adjustBankBalance/adjustBankBalanceListSlice";
import adjustBankBalanceCreateSlice from "./accounting/adjustBankBalance/adjustBankBalanceCreateSlice";
import adjustBankBalanceDetailsSlice from "./accounting/adjustBankBalance/adjustBankBalanceDetailsSlice";
import adjustBankBalanceEditSlice from "./accounting/adjustBankBalance/adjustBankBalanceEditSlice";
import adjustBankBalanceDeleteSlice from "./accounting/adjustBankBalance/adjustBankBalanceDeleteSlice";

import adjustCashBalanceCreateSlice from "./accounting/adjustCashBalance/adjustCashBalanceCreateSlice";
import adjustCashBalanceListSlice from "./accounting/adjustCashBalance/adjustCashBalanceListSlice";
import adjustCashBalanceDetailsSlice from "./accounting/adjustCashBalance/adjustCashBalanceDetailsSlice";
import adjustCashBalanceEditSlice from "./accounting/adjustCashBalance/adjustCashBalanceEditSlice";
import adjustCashBalanceDeleteSlice from "./accounting/adjustCashBalance/adjustCashBalanceDeleteSlice";

import transactionCreateSlice from "./accounting/transaction/transactionCreateSlice";
import transactionListSlice from "./accounting/transaction/transactionListSlice";
import transactionDetailsSlice from "./accounting/transaction/transactionDetailsSlice";
import transactionEditSlice from "./accounting/transaction/transactionEditSlice";
import transactionDeleteSlice from "./accounting/transaction/transactionDeleteSlice";

import transferCreateSlice from "./accounting/transfers/transferCreateSlice";
import transferListSlice from "./accounting/transfers/transferListSlice";
import transferDetailsSlice from "./accounting/transfers/transferDetailsSlice";
import transferEditSlice from "./accounting/transfers/transferEditSlice";
import transferDeleteSlice from "./accounting/transfers/transferDeleteSlice";
  
//Reports
import financeReportListSlice from "./reports/financeReportListSlice";

//Status
import projectStatusListSlice from "./demoSettings/status/project/projectStatusListSlice";
import projectStatusCreateSlice from "./demoSettings/status/project/projectStatusCreateSlice";
import projectStatusDetailsSlice from "./demoSettings/status/project/projectStatusDetailsSlice";
import projectStatusEditSlice from "./demoSettings/status/project/projectStatusEditSlice";
import projectStatusDeleteSlice from "./demoSettings/status/project/projectStatusDeleteSlice";

import taskStatusListSlice from "./demoSettings/status/task/taskStatusListSlice";
import taskStatusCreateSlice from "./demoSettings/status/task/taskStatusCreateSlice";
import taskStatusDetailsSlice from "./demoSettings/status/task/taskStatusDetailsSlice";
import taskStatusEditSlice from "./demoSettings/status/task/taskStatusEditSlice";
import taskStatusDeleteSlice from "./demoSettings/status/task/taskStatusDeleteSlice";

//Common
//Timezone
import timezoneCreateSlice from "./demoSettings/timezones/timezoneCreateSlice";
import timezoneListSlice from "./demoSettings/timezones/timezoneListSlice";
import timezoneDetailsSlice from "./demoSettings/timezones/timezoneDetailsSlice";
import timezoneEditSlice from "./demoSettings/timezones/timezoneEditSlice";
import timezoneDeleteSlice from "./demoSettings/timezones/timezoneDeleteSlice";

//Country
import countryCreateSlice from "./demoSettings/countries/countryCreateSlice";
import countryListSlice from "./demoSettings/countries/countryListSlice";
import countryDetailsSlice from "./demoSettings/countries/countryDetailsSlice";
import countryEditSlice from "./demoSettings/countries/countryEditSlice";
import countryDeleteSlice from "./demoSettings/countries/countryDeleteSlice";

//State
import stateCreateSlice from "./demoSettings/states/stateCreateSlice";
import stateListSlice from "./demoSettings/states/stateListSlice";
import stateDetailsSlice from "./demoSettings/states/stateDetailsSlice";
import stateEditSlice from "./demoSettings/states/stateEditSlice";
import stateDeleteSlice from "./demoSettings/states/stateDeleteSlice";



export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
    userSlice,
    staffListSlice,
    investorsListSlice,
    financialYearListSlice,
    balanceKeeperDetailsSlice,
    balanceKeeperDetailsEditSlice,
    bankAccountListSlice,
    addBankAccountSlice,
    bankAccountEditSlice,
    bankAccountDetailsSlice,
    investorEditSlice,
    investorAddSlice,
    investorDetailsSlice,
    staffDetailsSlice,
    staffDeleteSlice,
    salaryListSlice,
    salaryAddSlice,
    salaryDetailsSlice,
    salaryEditSlice,
    staffEditSlice,
    deleteBankAccountSlice,

    //new
    clientsListSlice,
    createClientsSlice,
    clientDetailsSlice,
    clientEditSlice,
    clientDeleteSlice,
    adjustBankBalanceListSlice,
    adjustBankBalanceCreateSlice,
    adjustBankBalanceDetailsSlice,
    adjustBankBalanceEditSlice,
    adjustBankBalanceDeleteSlice,
    adjustCashBalanceCreateSlice,
    adjustCashBalanceListSlice,
    adjustCashBalanceDetailsSlice,
    adjustCashBalanceEditSlice,
    adjustCashBalanceDeleteSlice,
    dashboardDataSlice,
    transCategoriesListSlice,
    transCategoriesAddSlice,
    transCategoriesDetailsSlice,
    transCategoriesEditSlice,
    transCategoriesDeleteSlice,
    transSubCategoriesListSlice,
    transSubCategoriesAddSlice,
    transSubCategoriesDetailsSlice,
    transSubCategoriesEditSlice,
    transSubCategoriesDeleteSlice,
    transactionCreateSlice,
    transactionListSlice,
    transactionDetailsSlice,
    transactionEditSlice,
    transactionDeleteSlice,
    transferCreateSlice,
    transferListSlice,
    transferDetailsSlice,
    transferEditSlice,
    transferDeleteSlice,
    dashboardReportSlice,
    financeReportListSlice,
    staffAddSlice,
    investorDeleteSlice,
    userProfileSlice,
    bankTransactionsListSlice,
    projectListSlice,
    projectDetailsSlice,
    createProjectSlice,
    projectEditSlice,
    projectDeleteSlice,
    timezoneCreateSlice,
    timezoneListSlice,
    timezoneDetailsSlice,
    timezoneEditSlice,
    timezoneDeleteSlice,
    countryCreateSlice,
    countryListSlice,
    countryDetailsSlice,
    countryEditSlice,
    countryDeleteSlice,
    stateCreateSlice,
    stateListSlice,
    stateEditSlice,
    stateDetailsSlice,
    stateDeleteSlice,
    projectStatusListSlice,
    projectStatusCreateSlice,
    projectStatusDetailsSlice,
    projectStatusEditSlice,
    projectStatusDeleteSlice,
    taskStatusListSlice,
    taskStatusCreateSlice,
    taskStatusDetailsSlice,
    taskStatusEditSlice,
    taskStatusDeleteSlice,
    taskListSlice,
    taskCreateSlice,
    taskDetailsSlice,
    taskEditSlice,
    taskDeleteSlice ,
    timelogCreateSlice,
    timelogListSlice,
    timelogDetailsSlice,
    timelogEditSlice,
    timelogDeleteSlice,
    timerDetailSlice,
    startTimerSlice,
    assigneeListSlice
  },
});
