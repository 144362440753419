import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const deleteProjectStatus = createAsyncThunk(
  "admin/delete-projectStatus",
  async ({ projectStatusID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.projectStatusDetailsURL.replace("{id}", projectStatusID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const projectStatusDeleteSlice = createSlice({
  name: "delete-projectStatus",
  initialState: {
    projectStatusDeleteFetching: false,
    projectStatusDeleteSuccess: false,
    projectStatusDeleteError: false,
    projectStatusDeleteErrorMessage: "",
  },
  reducers: {
    projectStatusDeleteClearState: (state) => {
      state.projectStatusDeleteError = false;
      state.projectStatusDeleteSuccess = false;
      state.projectStatusDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteProjectStatus.fulfilled]: (state, { payload }) => {
      state.projectStatusDeleteFetching = false;
      state.projectStatusDeleteSuccess = true;
      return state;
    },
    [deleteProjectStatus.rejected]: (state, action) => {
      state.projectStatusDeleteFetching = false;
      state.projectStatusDeleteError = true;
      state.projectStatusDeleteErrorMessage = action?.payload;
    },
    [deleteProjectStatus.pending]: (state) => {
      state.projectStatusDeleteFetching = true;
    },
  },
});

export const { projectStatusDeleteClearState } = projectStatusDeleteSlice.actions;

export default projectStatusDeleteSlice.reducer;
