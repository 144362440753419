import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const createClients = createAsyncThunk(
  "admin/create-clients",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.clientPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const createClientsSlice = createSlice({
  name: "create-clients",
  initialState: {
    clientCreateFetching: false,
    clientCreateSuccess: false,
    clientCreateError: false,
    clientCreateErrorMessage: "",
  },
  reducers: {
    clearClientCreateState: (state) => {
      state.clientCreateError = false;
      state.clientCreateSuccess = false;
      state.clientCreateFetching = false;

      return state;
    },
  },
  extraReducers: {
    [createClients.fulfilled]: (state, { payload }) => {
      state.clientCreateFetching = false;
      state.clientCreateSuccess = true;
      return state;
    },
    [createClients.rejected]: (state, action) => {
      state.clientCreateFetching = false;
      state.clientCreateError = true;
      state.clientCreateErrorMessage = action?.payload;
    },
    [createClients.pending]: (state) => {
      state.clientCreateFetching = true;
    },
  },
});

export const { clearClientCreateState } = createClientsSlice.actions;

export default createClientsSlice.reducer;
