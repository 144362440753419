import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const addAdjustCashBalance = createAsyncThunk(
  "admin/adjustCashBalance-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustCashBalancePostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adjustCashBalanceCreateSlice = createSlice({
  name: "adjustCashBalance-add",
  initialState: {
    addAdjustCashBalanceFetching: false,
    addAdjustCashBalanceSuccess: false,
    addAdjustCashBalanceError: false,
    addAdjustCashBalanceErrorMessage: "",
  },
  reducers: {
    clearAdjustCashBalanceState: (state) => {
      state.addAdjustCashBalanceError = false;
      state.addAdjustCashBalanceSuccess = false;
      state.addAdjustCashBalanceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addAdjustCashBalance.fulfilled]: (state, { payload }) => {
      state.addAdjustCashBalanceFetching = false;
      state.addAdjustCashBalanceSuccess = true;
      return state;
    },
    [addAdjustCashBalance.rejected]: (state, action) => {
      state.addAdjustCashBalanceFetching = false;
      state.addAdjustCashBalanceError = true;
      state.addAdjustCashBalanceErrorMessage = action?.payload;
    },
    [addAdjustCashBalance.pending]: (state) => {
      state.addAdjustCashBalanceFetching = true;
    },
  },
});

export const { clearAdjustCashBalanceState } =
  adjustCashBalanceCreateSlice.actions;

export default adjustCashBalanceCreateSlice.reducer;
