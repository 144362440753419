import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const editAdjustBankBalance = createAsyncThunk(
  "admin/adjustBankBalance-edit",
  async ({ payload, adjustBankBalanceID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.adjustBankBalanceDetailsURL.replace("{id}", adjustBankBalanceID),
        method: "PATCH",
        data: payload,
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const adjustBankBalanceEditSlice = createSlice({
  name: "adjustBankBalance-edit",
  initialState: {
    editAdjustBankBalanceFetching: false,
    editAdjustBankBalanceSuccess: false,
    editAdjustBankBalanceError: false,
    editAdjustBankBalanceErrorMessage: "",
  },
  reducers: {
    clearEditAdjustBankBalanceState: (state) => {
      state.editAdjustBankBalanceError = false;
      state.editAdjustBankBalanceSuccess = false;
      state.editAdjustBankBalanceFetching = false;

      return state;
    },
  },
  extraReducers: {
    [editAdjustBankBalance.fulfilled]: (state, { payload }) => {
      state.editAdjustBankBalanceFetching = false;
      state.editAdjustBankBalanceSuccess = true;
      return state;
    },
    [editAdjustBankBalance.rejected]: (state, action) => {
      state.editAdjustBankBalanceFetching = false;
      state.editAdjustBankBalanceError = true;
      state.editAdjustBankBalanceErrorMessage = action?.payload;
    },
    [editAdjustBankBalance.pending]: (state) => {
      state.editAdjustBankBalanceFetching = true;
    },
  },
});

export const { clearEditAdjustBankBalanceState } = adjustBankBalanceEditSlice.actions;

export default adjustBankBalanceEditSlice.reducer;
