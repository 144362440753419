import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchDashboardReportDetails = createAsyncThunk(
  "admin/dashboard-report",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.dashboardReportDetailsURL,
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const dashboardReportSlice = createSlice({
  name: "dashboard-report",
  initialState: {
    dashboardReportDetails: {},
    fdrdFetching: false,
    fdrdSuccess: false,
    fdrdError: false,
    fdrdErrorMessage: "",
  },
  reducers: {
    clearFdrdState: (state) => {
      state.fdrdError = false;
      state.fdrdSuccess = false;
      state.fdrdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchDashboardReportDetails.fulfilled]: (state, { payload }) => {
      state.dashboardReportDetails = payload.data;

      state.fdrdFetching = false;
      state.fdrdSuccess = true;
      return state;
    },
    [fetchDashboardReportDetails.rejected]: (state, action) => {
      state.fdrdFetching = false;
      state.fdrdError = true;
      state.fdrdErrorMessage = action?.payload;
    },
    [fetchDashboardReportDetails.pending]: (state) => {
      state.fdrdFetching = true;
    },
  },
});
export const { clearFdrdState } = dashboardReportSlice.actions;

export default dashboardReportSlice.reducer;
