import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchCountryList = createAsyncThunk(
  "admin/countries-list",
  async ({ limit, offset  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.countriesListURL.replace("{limit}", limit).replace("{offset}", offset),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const countryListSlice = createSlice({
  name: "countries-list",
  initialState: {
    countriesList: [],
    fdlFetching: false,
    fdlSuccess: false,
    fdlError: false,
    fdlErrorMessage: "",
    countryCount: null,
  },
  reducers: {
    fdlClearState: (state) => {
      state.fdlError = false;
      state.fdlSuccess = false;
      state.fdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCountryList.fulfilled]: (state, { payload }) => {
      state.countriesList = [];
      payload.data.results.forEach((item) => state.countriesList.push(item));
      state.countryCount = payload.data.count;
      state.fdlFetching = false;
      state.fdlSuccess = true;
      return state;
    },
    [fetchCountryList.rejected]: (state, action) => {
      state.fdlFetching = false;
      state.fdlError = true;
      state.fdlErrorMessage = action?.payload;
    },
    [fetchCountryList.pending]: (state) => {
      state.fdlFetching = true;
    },
  },
});

export const { fdlClearState } = countryListSlice.actions;

export default countryListSlice.reducer;
