import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTimezoneDetails = createAsyncThunk(
  "admin/timezone-details",
  async ({ timezoneID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timezoneDetailsURL.replace("{id}", timezoneID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timezoneDetailsSlice = createSlice({
  name: "timezone-details",
  initialState: {
    timezoneDetails: {},
    timezoneDetailsFetching: false,
    timezoneDetailsSuccess: false,
    timezoneDetailsError: false,
    timezoneDetailsErrorMessage: "",
  },
  reducers: {
    clearFddState: (state) => {
      state.timezoneDetailsError = false;
      state.timezoneDetailsSuccess = false;
      state.timezoneDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTimezoneDetails.fulfilled]: (state, { payload }) => {
      state.timezoneDetails = payload.data;

      state.timezoneDetailsFetching = false;
      state.timezoneDetailsSuccess = true;
      return state;
    },
    [fetchTimezoneDetails.rejected]: (state, action) => {
      state.timezoneDetailsFetching = false;
      state.timezoneDetailsError = true;
      state.timezoneDetailsErrorMessage = action?.payload;
    },
    [fetchTimezoneDetails.pending]: (state) => {
      state.timezoneDetailsFetching = true;
    },
  },
});
export const { clearFddState } = timezoneDetailsSlice.actions;

export default timezoneDetailsSlice.reducer;
