import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const deleteTaskStatus = createAsyncThunk(
  "admin/delete-taskStatus",
  async ({ taskStatusID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.taskStatusDetailsURL.replace("{id}", taskStatusID),
        method: "DELETE",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();
      return rejectWithValue(message);
    }
  }
);
const taskStatusDeleteSlice = createSlice({
  name: "delete-taskStatus",
  initialState: {
    taskStatusDeleteFetching: false,
    taskStatusDeleteSuccess: false,
    taskStatusDeleteError: false,
    taskStatusDeleteErrorMessage: "",
  },
  reducers: {
    taskStatusDeleteClearState: (state) => {
      state.taskStatusDeleteError = false;
      state.taskStatusDeleteSuccess = false;
      state.taskStatusDeleteFetching = false;

      return state;
    },
  },
  extraReducers: {
    [deleteTaskStatus.fulfilled]: (state, { payload }) => {
      state.taskStatusDeleteFetching = false;
      state.taskStatusDeleteSuccess = true;
      return state;
    },
    [deleteTaskStatus.rejected]: (state, action) => {
      state.taskStatusDeleteFetching = false;
      state.taskStatusDeleteError = true;
      state.taskStatusDeleteErrorMessage = action?.payload;
    },
    [deleteTaskStatus.pending]: (state) => {
      state.taskStatusDeleteFetching = true;
    },
  },
});

export const { taskStatusDeleteClearState } = taskStatusDeleteSlice.actions;

export default taskStatusDeleteSlice.reducer;
