import React, { memo } from "react";

//router
import { Switch, Redirect } from "react-router";

//layoutpages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/", component: () => <Redirect to="/dashboard" /> },
  
  //clients
  { path: "/clients", component: Default },
  
  //projects
  { path: "/projects", component: Default },
  { path: "/projects/:projectID/:active_tab?", component: Default },

  { path: "/tasks", component: Default },


  //staff
  { path: "/hr-management/:active_tab?", component: Default },

  //Cashbook
  { path: "/cashbook/income", component: Default },
  { path: "/cashbook/expenses", component: Default },
  { path: "/cashbook/adjust-bank-balance", component: Default },
  { path: "/cashbook/adjust-cash-balance", component: Default },
  { path: "/cashbook/transfers", component: Default },
  { path: "/bank/:bankID/transactions", component: Default },

  { path: "/settings/:active_tab?", component: Default },
  { path: "/report/finance", component: Default },
  { path: "/country/:countryID/states", component: Default },

  { path: "/investors", component: Default },
  { path: "/staff-profile", component: Default },
  { path: "/errors/error404", component: Simple,},
];

const authRoutes = [
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
