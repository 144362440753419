import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchCountryDetails = createAsyncThunk(
  "admin/country-details",
  async ({ countryID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.countryDetailsURL.replace("{id}", countryID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const countryDetailsSlice = createSlice({
  name: "country-details",
  initialState: {
    countryDetails: {},
    countryDetailsFetching: false,
    countryDetailsSuccess: false,
    countryDetailsError: false,
    countryDetailsErrorMessage: "",
  },
  reducers: {
    clearFddState: (state) => {
      state.countryDetailsError = false;
      state.countryDetailsSuccess = false;
      state.countryDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchCountryDetails.fulfilled]: (state, { payload }) => {
      state.countryDetails = payload.data;

      state.countryDetailsFetching = false;
      state.countryDetailsSuccess = true;
      return state;
    },
    [fetchCountryDetails.rejected]: (state, action) => {
      state.countryDetailsFetching = false;
      state.countryDetailsError = true;
      state.countryDetailsErrorMessage = action?.payload;
    },
    [fetchCountryDetails.pending]: (state) => {
      state.countryDetailsFetching = true;
    },
  },
});
export const { clearFddState } = countryDetailsSlice.actions;

export default countryDetailsSlice.reducer;
