import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTransactionCategoryDetails = createAsyncThunk(
  "admin/transaction-category-details",
  async ({ typeID }, thunkAPI) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionCategoryDetailsURL.replace("{id}", typeID),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const transCategoryDetailsSlice = createSlice({
  name: "transaction-category-details",
  initialState: {
    transCategoryDetails: {},
    tcdFetching: false,
    tcdSuccess: false,
    tcdError: false,
    tcderrorMessage: "",
  },
  reducers: {
    tcdClearState: (state) => {
      state.tcdError = false;
      state.tcdSuccess = false;
      state.tcdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTransactionCategoryDetails.fulfilled]: (state, { payload }) => {
      state.transCategoryDetails = payload.data;
      state.tcdFetching = false;
      state.tcdSuccess = true;
      return state;
    },
    [fetchTransactionCategoryDetails.rejected]: (state, { payload }) => {
      state.tcdFetching = false;
      state.tcdError = true;
      state.tcderrorMessage = payload?.message;
    },
    [fetchTransactionCategoryDetails.pending]: (state) => {
      state.tcdFetching = true;
    },
  },
});

export const { tcdClearState } = transCategoryDetailsSlice.actions;

export default transCategoryDetailsSlice.reducer;
