import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const timelogEdit = createAsyncThunk(
  "admin/timelog-edit",
  async ({payload, timelogID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timelogDetailsURL.replace("{id}", timelogID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timelogEditSlice = createSlice({
  name: "timelog-edit",
  initialState: {
    timelogEditFetching: false,
    timelogEditSuccess: false,
    timelogEditError: false,
    timelogEditErrorMessage: "",
  },
  reducers: {
    clearTimelogEditState: (state) => {
      state.timelogEditError = false;
      state.timelogEditSuccess = false;
      state.timelogEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [timelogEdit.fulfilled]: (state, { payload }) => {
      state.timelogEditFetching = false;
      state.timelogEditSuccess = true;
      return state;
    },
    [timelogEdit.rejected]: (state, action) => {
      state.timelogEditFetching = false;
      state.timelogEditError = true;
      state.timelogEditErrorMessage = action?.payload;
    },
    [timelogEdit.pending]: (state) => {
      state.timelogEditFetching = true;
    },
  },
});
export const { clearTimelogEditState } = timelogEditSlice.actions;

export default timelogEditSlice.reducer;
