import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../api";

export const addSalary = createAsyncThunk(
  "admin/salary-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.salaryAddURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const salaryAddSlice = createSlice({
  name: "salary-add",
  initialState: {
    addSalaryFetching: false,
    addSalarySuccess: false,
    addSalaryError: false,
    addSalaryErrorMessage: "",
  },
  reducers: {
    clearAddSalaryState: (state) => {
      state.addSalaryError = false;
      state.addSalarySuccess = false;
      state.addSalaryFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addSalary.fulfilled]: (state, { payload }) => {
      state.addSalaryFetching = false;
      state.addSalarySuccess = true;
      return state;
    },
    [addSalary.rejected]: (state, action) => {
      state.addSalaryFetching = false;
      state.addSalaryError = true;
      state.addSalaryErrorMessage = action?.payload;
    },
    [addSalary.pending]: (state) => {
      state.addSalaryFetching = true;
    },
  },
});

export const { clearAddSalaryState } = salaryAddSlice.actions;

export default salaryAddSlice.reducer;
