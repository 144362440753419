import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTimelogDetails = createAsyncThunk(
  "admin/timelog-details",
  async ({ timelogID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.timelogDetailsURL.replace("{id}", timelogID),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const timelogDetailsSlice = createSlice({
  name: "timelog-details",
  initialState: {
    timelogDetails: {},
    timelogDetailsFetching: false,
    timelogDetailsSuccess: false,
    timelogDetailsError: false,
    timelogDetailsErrorMessage: "",
  },
  reducers: {
    clearFddState: (state) => {
      state.timelogDetailsError = false;
      state.timelogDetailsSuccess = false;
      state.timelogDetailsFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTimelogDetails.fulfilled]: (state, { payload }) => {
      state.timelogDetails = payload.data;

      state.timelogDetailsFetching = false;
      state.timelogDetailsSuccess = true;
      return state;
    },
    [fetchTimelogDetails.rejected]: (state, action) => {
      state.timelogDetailsFetching = false;
      state.timelogDetailsError = true;
      state.timelogDetailsErrorMessage = action?.payload;
    },
    [fetchTimelogDetails.pending]: (state) => {
      state.timelogDetailsFetching = true;
    },
  },
});
export const { clearFddState } = timelogDetailsSlice.actions;

export default timelogDetailsSlice.reducer;
