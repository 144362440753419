import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const fetchFinanceReport = createAsyncThunk(
  "admin/report-list",
  async ({ fromDate, toDate }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url:
          (fromDate === "" || toDate === "")
            ? api.reportsListURL
                .replace("?from_date={from_date}", "")
                .replace("&to_date={to_date}", "")
            : api.reportsListURL
                .replace("{from_date}", fromDate)
                .replace("{to_date}", toDate),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const financeReportListSlice = createSlice({
  name: "report-list",
  initialState: {
    financeReport: {},
    rplFetching: false,
    rplSuccess: false,
    rplError: false,
    rplErrorMessage: "",
  },
  reducers: {
    rplClearState: (state) => {
      state.rplError = false;
      state.rplSuccess = false;
      state.rplFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchFinanceReport.fulfilled]: (state, { payload }) => {
      state.financeReport = payload.data;

      state.rplFetching = false;
      state.rplSuccess = true;
      return state;
    },
    [fetchFinanceReport.rejected]: (state, action) => {
      state.rplFetching = false;
      state.rplError = true;
      state.rplErrorMessage = action?.payload;
    },
    [fetchFinanceReport.pending]: (state) => {
      state.rplFetching = true;
    },
  },
});

export const { rplClearState } = financeReportListSlice.actions;

export default financeReportListSlice.reducer;
