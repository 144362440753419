import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTasksList = createAsyncThunk(
  "admin/tasks-list",
  async ({ limit, offset,projectID  }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: projectID?api.tasksListURL.replace("{project_id}",projectID).replace("{limit}", limit).replace("{offset}", offset): api.tasksListURL.replace("{limit}", limit).replace("{offset}", offset).replace("&project_id={project_id}",''),
        method: "GET",
      });

      let data = await response;
      if (response.status === 200) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const taskListSlice = createSlice({
  name: "tasks-list",
  initialState: {
    tasksList: [],
    fdlFetching: false,
    fdlSuccess: false,
    fdlError: false,
    fdlErrorMessage: "",
    taskCount: null,
  },
  reducers: {
    fdlClearState: (state) => {
      state.fdlError = false;
      state.fdlSuccess = false;
      state.fdlFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTasksList.fulfilled]: (state, { payload }) => {
      state.tasksList = [];
      payload.data.results.forEach((item) => state.tasksList.push(item));
      state.taskCount = payload.data.count;
      state.fdlFetching = false;
      state.fdlSuccess = true;
      return state;
    },
    [fetchTasksList.rejected]: (state, action) => {
      state.fdlFetching = false;
      state.fdlError = true;
      state.fdlErrorMessage = action?.payload;
    },
    [fetchTasksList.pending]: (state) => {
      state.fdlFetching = true;
    },
  },
});

export const { fdlClearState } = taskListSlice.actions;

export default taskListSlice.reducer;
