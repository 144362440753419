import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const addTransfer = createAsyncThunk(
  "admin/transfer-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transferPostURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transferCreateSlice = createSlice({
  name: "transfer-add",
  initialState: {
    addTransferFetching: false,
    addTransferSuccess: false,
    addTransferError: false,
    addTransferErrorMessage: "",
  },
  reducers: {
    clearAddTransfer: (state) => {
      state.addTransferError = false;
      state.addTransferSuccess = false;
      state.addTransferFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addTransfer.fulfilled]: (state, { payload }) => {
      state.addTransferFetching = false;
      state.addTransferSuccess = true;
      return state;
    },
    [addTransfer.rejected]: (state, action) => {
      state.addTransferFetching = false;
      state.addTransferError = true;
      state.addTransferErrorMessage = action?.payload;
    },
    [addTransfer.pending]: (state) => {
      state.addTransferFetching = true;
    },
  },
});

export const { clearAddTransfer } =
  transferCreateSlice.actions;

export default transferCreateSlice.reducer;
