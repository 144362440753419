import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

export const addInvestor = createAsyncThunk(
  "admin/investor-add",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.investorAddURL,
        method: "POST",
        data: payload,
      });

      let data = await response;
      if (response.status === 201) {
        let sam = response.data;
        return sam;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const investorAddSlice = createSlice({
  name: "investor-add",
  initialState: {
    addInvestorFetching: false,
    addInvestorSuccess: false,
    addInvestorError: false,
    addInvestorErrorMessage: "",
  },
  reducers: {
    clearAddInvestorState: (state) => {
      state.addInvestorError = false;
      state.addInvestorSuccess = false;
      state.addInvestorFetching = false;

      return state;
    },
  },
  extraReducers: {
    [addInvestor.fulfilled]: (state, { payload }) => {
      state.addInvestorFetching = false;
      state.addInvestorSuccess = true;
      return state;
    },
    [addInvestor.rejected]: (state, action) => {
      state.addInvestorFetching = false;
      state.addInvestorError = true;
      state.addInvestorErrorMessage = action?.payload;
    },
    [addInvestor.pending]: (state) => {
      state.addInvestorFetching = true;
    },
  },
});

export const { clearAddInvestorState } = investorAddSlice.actions;

export default investorAddSlice.reducer;
