import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const stateEdit = createAsyncThunk(
  "admin/state-edit",
  async ({payload, stateID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.stateDetailsURL.replace("{id}", stateID),
        method: "PATCH",
        data:payload
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const stateEditSlice = createSlice({
  name: "state-edit",
  initialState: {
    stateEditFetching: false,
    stateEditSuccess: false,
    stateEditError: false,
    stateEditErrorMessage: "",
  },
  reducers: {
    clearStateEditState: (state) => {
      state.stateEditError = false;
      state.stateEditSuccess = false;
      state.stateEditFetching = false;

      return state;
    },
  },
  extraReducers: {
    [stateEdit.fulfilled]: (state, { payload }) => {
      state.stateEditFetching = false;
      state.stateEditSuccess = true;
      return state;
    },
    [stateEdit.rejected]: (state, action) => {
      state.stateEditFetching = false;
      state.stateEditError = true;
      state.stateEditErrorMessage = action?.payload;
    },
    [stateEdit.pending]: (state) => {
      state.stateEditFetching = true;
    },
  },
});
export const { clearStateEditState } = stateEditSlice.actions;

export default stateEditSlice.reducer;
