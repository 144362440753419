import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api";

export const fetchTransactionDetails = createAsyncThunk(
  "admin/Transaction-details",
  async ({ transactionID }, { rejectWithValue }) => {
    try {
      const response = await api.actionHandler({
        url: api.transactionDetailsURL.replace(
          "{id}",
          transactionID
        ),
        method: "GET",
      });
      let data = await response;
      if (response.status === 200) {
        let result = response.data;
        return result;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString();

      return rejectWithValue(message);
    }
  }
);
const transactionDetailsSlice = createSlice({
  name: "Transaction-details",
  initialState: {
    transactionDetails: {},
    itdFetching: false,
    itdSuccess: false,
    itdError: false,
    itdErrorMessage: "",
  },
  reducers: {
    clearItdState: (state) => {
      state.itdError = false;
      state.itdSuccess = false;
      state.itdFetching = false;

      return state;
    },
  },
  extraReducers: {
    [fetchTransactionDetails.fulfilled]: (state, { payload }) => {
      state.transactionDetails = payload.data;

      state.itdFetching = false;
      state.itdSuccess = true;
      return state;
    },
    [fetchTransactionDetails.rejected]: (state, action) => {
      state.itdFetching = false;
      state.itdError = true;
      state.itdErrorMessage = action?.payload;
    },
    [fetchTransactionDetails.pending]: (state) => {
      state.itdFetching = true;
    },
  },
});
export const { clearItdState } = transactionDetailsSlice.actions;

export default transactionDetailsSlice.reducer;
